import { useCookie } from 'nuxt/app'
import { jwtDecode } from 'jwt-decode'
import type { UserJWT } from '@/types/jwt'
import { useFulfillmentOrder } from '@/composables/useFulfillmentOrder'
import { defineNuxtPlugin, isEmpty, navigateTo } from '#imports'
import { useGuestStore } from '~/store/guest'

export default defineNuxtPlugin((nuxtApp: any) => {
  const api = $fetch.create({
    onRequest({ options }) {
      nuxtApp.runWithContext(() => {
        const { guest, setLoggedInToken } = useGuestStore()

        if (guest.loggedInToken && !isTokenValid(guest.loggedInToken)) {
          setLoggedInToken('')
        }

        if (!isEmpty(options.headers)) {
          return
        }
        const employerAccessToken = useCookie('employer_access_token')
        if (employerAccessToken.value) {
          options.headers ||= new Headers()
          options.headers.set(
            'x-authorization-obo',
            `Bearer ${employerAccessToken.value}`,
          )
          return
        }

        const token = guest.loggedInToken || guest.token
        if (token) {
          options.headers ||= new Headers()
          options.headers.set('x-authorization-obo', `Bearer ${token}`)
        }
      })
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        const { guest, reset: logout, setLoggedInToken } = useGuestStore()

        if (guest.loggedInToken && !isTokenValid(guest.loggedInToken)) {
          setLoggedInToken('')
          return
        }

        const { emailToken, clearEmailToken } = useFulfillmentOrder()
        if (emailToken.value && !isTokenValid(emailToken.value)) {
          clearEmailToken()
          return
        }

        logout()
        await nuxtApp.runWithContext(() =>
          navigateTo('/welcome', { external: true }),
        )
      }
    },
  })

  function isTokenValid(token: string): boolean {
    try {
      const { exp } = jwtDecode<UserJWT>(token)
      return exp * 1000 > Date.now()
    } catch {
      return false
    }
  }

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api,
    },
  }
})
