import VueTippy from 'vue-tippy'
import { defineNuxtPlugin } from '#imports'
import 'tippy.js/dist/tippy.css'
export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTippy, {
    defaultProps: {
      interactive: true,
      theme: 'light',
      animateFill: false,
      arrow: true,
      arrowType: 'round',
      placement: 'bottom',
      trigger: 'click',
    },
  })
})
