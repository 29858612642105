import { defineNuxtPlugin, useRuntimeConfig } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const fittingboxApiKey = useRuntimeConfig().public?.fittingboxApiKey ?? ''

  const fittingboxContext = {
    initFittingbox: (containerId: string) => {
      const params = {
        apiKey: fittingboxApiKey,
        popupIntegration: {
          centeredHorizontal: true,
          centeredVertical: true,
          width: '768px',
          height: '576px',
        },
        onLiveStatus: (data: any) => {
          if (data.userHasGlasses) {
            window.fitmixInstance.setLensesAntireflection(true)
          }
        },
      }
      window.fitmixInstance = window.FitMix.createWidget(
        containerId,
        params,
        function () {
          // eslint-disable-next-line no-console
          console.log('FitmixWidget creation complete!')
        },
      )
    },
    selectFrame: (sku: string) => {
      window.fitmixInstance.setFrame(sku)
      window.fitmixInstance.startVto('live')
    },
  }

  nuxtApp.provide('fittingboxContext', fittingboxContext)
})
