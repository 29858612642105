import { useFetch, defineNuxtPlugin } from 'nuxt/app'

export default defineNuxtPlugin((nuxtApp) => {
  const clients = {
    getEntries: async (params: any) => {
      try {
        const result = await useFetch('/api/getContent', {
          query: params,
          // Add cache control options to help with page refresh
          headers: {
            'Cache-Control': 'no-cache',
          },
          // Add key based on params to prevent stale cache
          key: `contentful-${JSON.stringify(params)}`,
          // Add retry capability
          retry: 2,
        })

        return result
      } catch (error) {
        console.error('Error fetching from Contentful API:', error, { params })
        // Return default structure to prevent errors
        return { data: { value: { items: [] } } }
      }
    },
  }

  nuxtApp.provide('contentfulClient', clients)
})
