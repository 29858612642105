import { defineStore } from 'pinia'
import type { ZnAddress } from '@/types/address'
import { piniaPluginPersistedstate } from '#imports'
import { emptyAddress } from '~/components/Zenni/ZnAutocompleteAddressForm/constants'
import type { ZnShippingOption } from '~/components/Zenni/ZnShippingOptions/types'

interface BillingAddress extends ZnAddress {
  sameAsShippingAddress: boolean
}

export interface CheckoutState {
  loading: boolean
  shippingAddress: ZnAddress
  shippingOption: ZnShippingOption | null
  marketingSubscribe: boolean
  billingAddress: BillingAddress
}

export const useCheckoutStore = defineStore('checkout', {
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage(),
  },
  state: (): CheckoutState => ({
    loading: false,
    shippingAddress: emptyAddress,
    shippingOption: null,
    marketingSubscribe: false,
    billingAddress: { ...emptyAddress, sameAsShippingAddress: true },
  }),
  getters: {
    all: (state) => {
      return {
        loading: state.loading,
        shippingAddress: state.shippingAddress,
        billingAddress: state.billingAddress,
      }
    },
  },
  actions: {
    setLoading(loading: boolean) {
      this.loading = loading
    },
    setShippingAddress(address: ZnAddress) {
      this.shippingAddress = address
    },
    setShippingOption(option: ZnShippingOption) {
      this.shippingOption = option
    },
    setMarketingSubscribe(subscribe: boolean) {
      this.marketingSubscribe = subscribe
    },
    setBillingAddress(address: BillingAddress) {
      this.billingAddress = address
    },
    reset() {
      this.loading = false
      this.shippingAddress = emptyAddress
      this.billingAddress = { ...emptyAddress, sameAsShippingAddress: true }
      this.shippingOption = null
      this.marketingSubscribe = false
    },
  },
})
