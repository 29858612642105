import { ref } from 'vue'
import { defineStore } from 'pinia'
import { piniaPluginPersistedstate, useState } from '#imports'

export type CatalogButton = {
  code: 'vision' | 'safety'
  title: string
  subTitle: string
  img: string
  icon?: string
}
export const guestCatalogs: CatalogButton[] = [
  {
    code: 'vision',
    title: 'Eyeglasses & Sunglasses',
    subTitle: 'Stylish, everyday wear for work, leisure, and outdoors.',
    img: 'img/catalog-onboard/vision.png',
    icon: 'ZenniIconsIconEyeglass',
  },
  {
    code: 'safety',
    title: 'Safety Glasses',
    subTitle: 'Durable, protective frames designed for workplace safety.',
    img: 'img/catalog-onboard/safety.png',
    icon: 'ZenniIconsIconSafety',
  },
]

export const useOnboardStore = defineStore(
  'onboard',
  () => {
    const welcomed = ref<boolean>(false)
    const sessionOnboarded = ref<boolean>(false)
    const ctaShown = ref<boolean>(false)
    const ctaSwitchCatalogShown = ref<boolean>(true)
    const switchCatalogOnboarded = useState<boolean>(() => false)

    const setSessionOnboarded = (value: boolean) => {
      sessionOnboarded.value = value
    }

    const setWelcomed = (value: boolean) => {
      welcomed.value = value
    }

    const setCtaShown = (value: boolean) => {
      ctaShown.value = value
    }

    const setSwitchCatalogOnboarded = (value: boolean) => {
      switchCatalogOnboarded.value = value
    }

    const setSwitchCatalogCtaShown = (value: boolean) => {
      ctaSwitchCatalogShown.value = value
    }

    return {
      welcomed,
      sessionOnboarded,
      ctaShown,
      switchCatalogOnboarded,
      ctaSwitchCatalogShown,
      setCtaShown,
      setWelcomed,
      setSessionOnboarded,
      setSwitchCatalogOnboarded,
      setSwitchCatalogCtaShown,
    }
  },
  {
    persist: [
      {
        storage: piniaPluginPersistedstate.cookies({
          sameSite: 'strict',
          expires: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        }),
        pick: ['welcomed', 'switchCatalogOnboarded'],
      },
      {
        storage: piniaPluginPersistedstate.sessionStorage(),
        pick: ['ctaShown', 'sessionOnboarded', 'ctaSwitchCatalogShown'],
      },
    ],
  },
)
