import { omit } from 'lodash-es'
import type {
  CartServiceProps,
  BundleItemProps,
  CartServiceItemProps,
} from '@/types/cartService'
import type { CartResponseSuccess, ZnCartBundleMap } from '@/types/cart'

const getLensType = (prescription: any) => {
  const map: { [key: string]: any } = {
    bifocal: 'Bifocals',
    singlevision: 'SingleVision',
    nonprescription: 'NonRx',
    progressive: {
      standard: 'StandardProgressive',
      premium: 'PremiumProgressive',
    },
  }
  const lensType = map[prescription.lensType]
  if (typeof lensType !== 'string') {
    return lensType[prescription.progressiveType]
  }
  return lensType
}

export const regularItemsBundleMap = (
  bundleMaps?: CartResponseSuccess['bundle_map'],
) => {
  // return bundleMaps
  if (!bundleMaps) return undefined
  return Object.entries(bundleMaps).reduce(
    (acc, [key, value]: [string, ZnCartBundleMap]) => {
      if (value.isRegularItem) {
        acc[key] = value
      }
      return acc
    },
    {} as CartResponseSuccess['bundle_map'],
  )
}

const normalizationCart = (cart?: CartServiceProps): CartServiceProps => {
  const regularBundleMaps = regularItemsBundleMap(cart?.bundle_map)
  if (!cart || !regularBundleMaps || !cart?.items) return {} as CartServiceProps

  const cartItems = Object.entries(regularBundleMaps).map(
    ([key, value]: any) => ({
      bundleItemId: key,
      ...value,
    }),
  )

  const items = cartItems?.map((bundleItem) => {
    const bundleItems = bundleItem?.items?.map((item: BundleItemProps) => {
      const normalizeBundleItem = cart?.items?.find(
        (cartItem: any) => cartItem?.uid === item?.uid,
      ) as any

      return {
        ...normalizeBundleItem,
        ...item,
        quantity: 1,
        prices: {
          ...normalizeBundleItem?.prices,
          row_total: {
            ...normalizeBundleItem?.prices?.row_total,
            value:
              normalizeBundleItem?.prices?.row_total?.value /
              normalizeBundleItem?.quantity,
          },
          row_total_including_tax: {
            ...normalizeBundleItem?.prices?.row_total_including_tax,
            value:
              normalizeBundleItem?.prices?.row_total_including_tax?.value /
              normalizeBundleItem?.quantity,
          },
        },
      }
    })

    const subtotalBundleItemPrice = bundleItems.reduce(
      (sum: number, item: BundleItemProps) => {
        return sum + item.prices.row_total.value
      },
      0,
    )

    const prescription = bundleItem.prescription
    return {
      ...omit(bundleItem, 'items'),
      bundleItems,
      prescription: prescription.lensType && {
        ...prescription,
        lensType: getLensType(prescription),
      },
      subtotalBundleItemPrice,
    }
  }) as CartServiceItemProps[]

  return {
    ...omit(cart, 'bundle_map', 'items'),
    items,
  }
}

export { normalizationCart }
