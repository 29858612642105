import { defineNuxtRouteMiddleware, navigateTo, useCookie } from 'nuxt/app'
import { useSiteConfigurationStore } from '@/store/siteConfiguration'
import { useGuestStore } from '~/store/guest'
import { useGuestCartStore } from '~/store/guestCart'
import { useCheckoutStore } from '~/store/checkout'

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.skipMiddleware) return
  // In case nuxt image proxyies the image url here, we need to bypass it
  // as it doesn't have the gift query param and will reset the guest store
  if (to.path.includes('/cdn-cgi/image/')) {
    return
  }

  if (process.client) {
    const { gift: giftQueryParam, campaign } = to.query
    const guestStore = useGuestStore()
    const guestCartStore = useGuestCartStore()
    const checkoutStore = useCheckoutStore()
    const { getSiteConfiguration } = useSiteConfigurationStore()
    if (guestStore.isGuestMode && guestStore.gift?.storeId) {
      return
    }

    if (campaign && (!guestStore.gift?.id || guestStore.gift?.policyId !== String(campaign))) {
      guestStore.reset()
      guestCartStore.reset()
      checkoutStore.reset()
      await guestStore.loadGift(campaign as string)

      if (!guestStore.gift?.id) {
        // Gift expired
        if (guestStore.requestError?.statusCode === 410) {
          return navigateTo('/expired')
        }
        return navigateTo('/welcome')
      }
      const storeCode = guestStore.gift?.code?.replaceAll('_', ' ') as string
      await Promise.all([
        getSiteConfiguration(storeCode),
        guestStore.loginGuestUser(),
      ])
      if (guestStore.requestError) {
        return navigateTo('/welcome')
      }

      return
    }

    if (
      !guestStore.gift?.id ||
      (giftQueryParam && guestStore.gift?.id !== String(giftQueryParam))
    ) {
      guestStore.reset()
      guestCartStore.reset()
      checkoutStore.reset()
      useCookie('employer_access_token').value = null
      useCookie('employer_store_id').value = null
      useCookie('employer_campaign_id').value = null

      if (giftQueryParam) {
        await guestStore.loadGift(giftQueryParam as string)
        if (!guestStore.gift?.id) {
          // Gift expired
          if (guestStore.requestError?.statusCode === 410) {
            return navigateTo('/expired')
          }
          return navigateTo('/welcome', { external: true })
        }
        const storeCode = guestStore.gift?.code?.replaceAll('_', ' ') as string
        await Promise.all([
          getSiteConfiguration(storeCode),
          guestStore.loginGuestUser(),
        ])
        if (guestStore.requestError) {
          return navigateTo('/welcome', { external: true })
        }
        if (guestStore.dependents === null) {
          await guestStore.loadDependents()
        }
      } else {
        return navigateTo('/welcome', { external: true })
      }
    } else if (guestStore.gift?.id && !giftQueryParam) {
      return navigateTo({
        path: to.path,
        query: { ...to.query, gift: guestStore.gift?.id },
      })
    } else if (guestStore.dependents === null) {
      await guestStore.loadDependents()
    }
  }
})
