import payload_plugin_G60NNGJyVB from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_IlgkjG2a6p from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uqM44aGXv6 from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_3MFaVjI0h8 from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kCNbIgovSh from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_FWYSAFV8le from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_EoiUDH3XcL from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_85t9PBxwkC from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_cSEzOeV0j3 from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.8.2_vue@3.5.13_typescript@5.8.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/node/app/rdp-employee/.nuxt/components.plugin.mjs";
import prefetch_client_YKJh0IVRCD from "/home/node/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_eslint@9.21.0_jiti@2.4._a52a08763c51121e4dcdb6b75b8390b1/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import components_client_gIIPAHYU2N from "/home/node/app/node_modules/.pnpm/nuxt-swiper@2.0.0_magicast@0.3.5/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import plugin_YCPMwTaORR from "/home/node/app/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescr_0207495bb835a6f5ae7860b54eb3353f/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import plugin_RHIDFUAC0F from "/home/node/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.15.4_@parcel+watcher@2.5.1_@type_ff5d75c6404ca4b8a5135ca7ad7a8512/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import algolia_UEeqj5fYkj from "/home/node/app/base/plugins/algolia.ts";
import contentful_nJvUH9jWIP from "/home/node/app/base/plugins/contentful.ts";
import magentoCookieState_RkPgdzD2l8 from "/home/node/app/base/plugins/magentoCookieState.ts";
import vue_query_sbXI9EtIIL from "/home/node/app/base/plugins/vue-query.ts";
import vue_tailwind_datepicker_client_0hcucy5UBY from "/home/node/app/base/plugins/vue-tailwind-datepicker.client.ts";
import vue_tel_input_client_p4bs0Ud1JC from "/home/node/app/base/plugins/vue-tel-input.client.ts";
import algolia_config_m5rTH05vbp from "/home/node/app/rdp-employee/plugins/algolia-config.ts";
import api_GFfDXud5Cr from "/home/node/app/rdp-employee/plugins/api.ts";
import braintree_client_RKQioOlrbn from "/home/node/app/rdp-employee/plugins/braintree.client.ts";
import contentKey_kG2JAak4UX from "/home/node/app/rdp-employee/plugins/contentKey.ts";
import fittingboxPlugin_fNhCdApc9T from "/home/node/app/rdp-employee/plugins/fittingboxPlugin.ts";
import tippy_xFBYqfAPCu from "/home/node/app/rdp-employee/plugins/tippy.ts";
import vtoScanPlugin_JSUOmvBKkO from "/home/node/app/rdp-employee/plugins/vtoScanPlugin.ts";
import vue_the_mask_uOehdyQKKA from "/home/node/app/rdp-employee/plugins/vue-the-mask.ts";
import vue_touch_I89OnsDWx9 from "/home/node/app/rdp-employee/plugins/vue-touch.ts";
export default [
  payload_plugin_G60NNGJyVB,
  revive_payload_client_IlgkjG2a6p,
  unhead_uqM44aGXv6,
  router_3MFaVjI0h8,
  payload_client_kCNbIgovSh,
  navigation_repaint_client_FWYSAFV8le,
  check_outdated_build_client_EoiUDH3XcL,
  chunk_reload_client_85t9PBxwkC,
  plugin_vue3_cSEzOeV0j3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_YKJh0IVRCD,
  components_client_gIIPAHYU2N,
  plugin_YCPMwTaORR,
  plugin_RHIDFUAC0F,
  algolia_UEeqj5fYkj,
  contentful_nJvUH9jWIP,
  magentoCookieState_RkPgdzD2l8,
  vue_query_sbXI9EtIIL,
  vue_tailwind_datepicker_client_0hcucy5UBY,
  vue_tel_input_client_p4bs0Ud1JC,
  algolia_config_m5rTH05vbp,
  api_GFfDXud5Cr,
  braintree_client_RKQioOlrbn,
  contentKey_kG2JAak4UX,
  fittingboxPlugin_fNhCdApc9T,
  tippy_xFBYqfAPCu,
  vtoScanPlugin_JSUOmvBKkO,
  vue_the_mask_uOehdyQKKA,
  vue_touch_I89OnsDWx9
]