import { cloneDeep, forEach, find } from 'lodash-es'
import {
  estimationShippingArrivalTime,
  formatDaysLater,
} from '@/utils/normalize/estimatedArrivalRanges'

import type { FulfillmentPaymentDetails } from '@/composables/useFulfillmentOrder'
import {
  formatNumberToCustomString,
  formatNumberToString,
  normalizationDetail,
} from './prescriptionNormalize'

const defaultPhoneNumber: { [key: string]: any } = {
  US: '+12015550123',
  CA: '+12042345678',
  CN: '+8613123456789',
  AR: '+540111523456789',
  CL: '+56961234567',
  IN: '+9109987654321',
}

const formatTelPhoneDefault = (countryCode: string = 'US') => {
  return defaultPhoneNumber[countryCode]
}

const giftTypeNormalize = (payments: FulfillmentPaymentDetails[]) => {
  if (!payments || payments.length === 0) return ''

  const findType = (type: string) =>
    payments.some(
      (payment: FulfillmentPaymentDetails) => payment?.type === type,
    )

  if (findType('RDP_FREE_ITEM_GIFT_CARD')) {
    return 'freeItem'
  } else if (findType('CREDIT_CARD') && payments.length === 1) {
    if (findType('RDP_CREDIT_GIFT_CARD') && findType('CREDIT_CARD')) {
      return 'giftCard'
    }

    return 'recipientPays'
  } else if (findType('RDP_CREDIT_GIFT_CARD') && payments.length === 1) {
    return 'giftCard'
  } else if (findType('RDP_CREDIT_GIFT_CARD') && findType('CREDIT_CARD')) {
    return 'giftCard'
  }

  return ''
}

const orderAddressNormalize = (address: {
  city?: string
  country?: string
  firstName?: string
  lastName?: string
  zip?: string
  stateCode?: string
  region_id?: string
  state?: string
  street?: string
  id?: string
  telephone?: string
}) => {
  if (!address) return null

  return {
    city: address?.city || '',
    country_code: address?.country || '',
    firstname: address?.firstName || '',
    lastname: address?.lastName || '',
    postcode: address?.zip || '',
    region: address?.stateCode || '',
    region_id: address?.region_id || 0,
    region_label: address?.state || '',
    street: [address?.street || ''],
    id: address.id,
    telephone: Object.values(defaultPhoneNumber).includes(
      address?.telephone || '',
    )
      ? ''
      : address?.telephone || '',
  }
}

const fulfillmentShippingAddressNormalize = (address: {
  city?: string
  country?: string
  firstName?: string
  lastName?: string
  zip?: string
  stateCode?: string
  region_id?: string
  state?: string
  street?: string
  id?: string
  phoneNumber?: string
  // in case backend order is not available, we want to get the shipping address from ff data
  address1?: string
  address2?: string
  first_name?: string
  last_name?: string
  postal_code?: string
}) => {
  if (!address) return null
  return {
    city: address?.city || '',
    country_code: address?.country || '',
    firstname: address?.firstName || address?.first_name || '',
    lastname: address?.lastName || address?.last_name || '',
    postcode: address?.zip || address?.postal_code || '',
    region: address?.stateCode || '',
    region_id: address?.region_id || 0,
    region_label: address?.state || '',
    street: [address?.street || (address?.address1 ? `${address.address1}${address?.address2 ? `, ${address.address2}` : ''}` : '') || ''],
    id: address.id,
    telephone: Object.values(defaultPhoneNumber).includes(
      address?.phoneNumber || '',
    )
      ? ''
      : address?.phoneNumber || '',
  }
}

const fulfillmentAddressNormalize = (address: {
  city?: string
  country?: string
  first_name?: string
  last_name?: string
  postal_code?: string
  stateCode?: string
  region_id?: string
  state?: string
  address1?: string
  address2?: string
  id?: string
  phoneNumber?: string
}) => {
  if (!address) return null

  return {
    city: address?.city || '',
    country_code: address?.country || '',
    firstname: address?.first_name || '',
    lastname: address?.last_name || '',
    postcode: address?.postal_code || '',
    region: address?.stateCode || '',
    region_id: address?.region_id || 0,
    region_label: address?.state || '',
    street: address?.address2
      ? [address?.address1 || '', address?.address2]
      : [address?.address1 || ''],
    id: address.id,
    telephone: Object.values(defaultPhoneNumber).includes(
      address?.phoneNumber || '',
    )
      ? ''
      : address?.phoneNumber || '',
  }
}

const prismNormalize = (prescription: { [x: string]: any }, type: string) => {
  return {
    sphere: formatNumberToCustomString(prescription?.[`${type}_sphere`]),
    cylinders: formatNumberToCustomString(prescription?.[`${type}_cylinder`]),
    axis: prescription?.[`${type}_axis`],
    add: formatNumberToString(prescription?.nv_add),
    prismHor: prescription?.[`${type}_prism_horizontal_value`],
    prismVer: prescription?.[`${type}_prism_vertical_value`],
    baseHor: prescription?.[`${type}_base_horizontal_direction`],
    baseVer: prescription?.[`${type}_base_vertical_direction`],
  }
}

function areAllValuesNullExceptTwo(obj: any, exceptions: string | string[]) {
  for (const key in obj) {
    if (!exceptions.includes(key) && obj[key] !== null) {
      return false
    }
  }
  return true
}

const prescriptionInformationNormalize = (prescription: {
  pd_single?: number
  pd_type: string | number
  nv_add: number
  ar_coating?: string
  rx_lens_type?: string
  tint?: string
  pd_left?: number
  pd_right?: number
  ocrRequestId?: string
}) => {
  if (!prescription) return null

  const exceptions = ['ar_coating', 'rx_lens_type']

  const nonRxType =
    Object.values(prescription).every((item) => item == null) ||
    areAllValuesNullExceptTwo(prescription, exceptions)

  return {
    pd: prescription?.pd_single,
    pdLeft: prescription?.pd_left,
    pdRight: prescription?.pd_right,
    od: normalizationDetail(prismNormalize(prescription, 'od')),
    os: normalizationDetail(prismNormalize(prescription, 'os')),
    lensType: nonRxType ? 'Non Rx' : '',
    nvAdd: prescription?.nv_add,
    tint: prescription?.tint,
    ocrRequestId: prescription?.ocrRequestId,
  }
}

const ecpInfoNormalize = (ecpInfo: {
  firstName?: string
  lastName?: string
  ecpPartnerId?: string
}) => {
  const { firstName = '', lastName = '', ecpPartnerId = '' } = ecpInfo

  const patientName = `${firstName} ${lastName}`

  return {
    patientName,
    partnerId: ecpPartnerId,
  }
}

const mergeBackendItemsToFFItems = (
  fulfillmentItems: any[],
  bundleItems: any[],
) => {
  const mergedData = cloneDeep(fulfillmentItems)

  forEach(mergedData, (entry) => {
    const bundleItem = bundleItems.find(({ items }: any) =>
      items.find((item: any) => item.sku === entry.frameSku),
    )
    forEach(entry.lineItems, (lineItem) => {
      const correspondingItem = find(bundleItem?.items, {
        itemType: lineItem.type,
      })
      if (correspondingItem) {
        lineItem.sku = correspondingItem.sku
      } else {
        lineItem.sku = entry.frameSku
      }
    })
    entry.lensType = bundleItem?.lensType
    entry.imageUrl = bundleItem?.thumbnail || entry.imageUrl
    entry.discount = bundleItem?.discount
    entry.itemSubtotalPrice = Number(bundleItem?.price ?? 0) ?? 0
    entry.prescription = prescriptionInformationNormalize(entry.prescription)
  })

  return mergedData
}

const orderDetailsNormalize = (fulfillmentData: any, backendData: any) => {
  const bundleItems = backendData?.bundleItems
  const sortedPackages =
    fulfillmentData?.packages?.sort(
      (a: { type: string }, b: { type: string }) =>
        a.type === 'virtual' ? 1 : b.type === 'virtual' ? -1 : 0,
    ) ?? []

  const fulfillmentOrderDetails = sortedPackages.flatMap(
    (pkg: { items: any, shipping_address: any }) =>
      (pkg.items ?? []).map(
        (item: {
          line_items: any[]
          type: string
          status: string
          quantity: number
          partner_id: string
          item_sku: string
          prescription: any
          shipping_address: any
          image_url: string
        }) => {
          const categories = ['frame', 'lens', 'coating', 'prism', 'tint']

          const findValue = (prefix: string, itemType: string) =>
            item.line_items?.find(
              (lineItem: { category: string }) =>
                lineItem.category === `${prefix}_${itemType}`,
            )?.value ?? 0

          const getItemDetails = (type: string) => {
            const itemType = type === 'frame' ? 'item' : type

            return {
              type,
              price: findValue('price', itemType),
              tax: findValue('tax', itemType),
              discount: findValue('discount', itemType),
            }
          }

          const lineItems =
            item.type === 'virtual'
              ? [
                {
                  type: 'other',
                  price: findValue('price', 'item'),
                  tax: findValue('tax', 'item'),
                  discount: findValue('discount', 'item'),
                },
              ]
              : categories.map((type) => getItemDetails(type))
          return {
            frameSku: item.item_sku,
            partner_id: item.partner_id,
            status: item.status,
            quantity: item.quantity,
            prescription: item.prescription,
            lineItems: lineItems?.filter(Boolean),
            shippingAddress: pkg.shipping_address,
            imageUrl: item.image_url,
          }
        },
      ),
  )

  const itemList = mergeBackendItemsToFFItems(
    fulfillmentOrderDetails,
    bundleItems,
  )
  const normalizedShippingAddress = fulfillmentShippingAddressNormalize({
    ...(backendData?.shippingAddress || (fulfillmentOrderDetails?.[0] ?? {}).shippingAddress),
    phoneNumber: fulfillmentData?.customer_details?.phone_number,
  })

  const paymentDetails = fulfillmentData?.payment_details?.payments ?? []
  const giftType = giftTypeNormalize(paymentDetails)

  const creditCardPaymentDetails = paymentDetails.find(
    (payment: { type: string }) => payment?.type === 'CREDIT_CARD',
  )

  const giftCardAmount =
    giftType === 'giftCard'
      ? paymentDetails.find(
        (item: { type: string }) => item?.type === 'RDP_CREDIT_GIFT_CARD',
      )?.amount
      : 0
  const finalOrderTotal =
    giftType === 'giftCard'
      ? creditCardPaymentDetails?.amount ?? 0
      : fulfillmentData?.order_total

  const normalizedBillingAddress = creditCardPaymentDetails
    ? fulfillmentAddressNormalize(creditCardPaymentDetails?.billing_address)
    : null

  const creditCardInfo = creditCardPaymentDetails
    ? {
      cardType: creditCardPaymentDetails?.cc_name,
      lastFour: creditCardPaymentDetails?.last_4_digit,
    }
    : null

  const shippingArrivalRange =
    Object.values(
      estimationShippingArrivalTime[
      sortedPackages[0]
        ?.shipping_type as keyof typeof estimationShippingArrivalTime
      ],
    ).sort((a, b) => a - b) ?? []

  const shippingMethod = {
    shippingType: sortedPackages[0]?.shipping_type ?? '',
    shippingPrice: sortedPackages[0]?.shipping_price ?? 0,
    estimatedArraivalRange: shippingArrivalRange
      ? shippingArrivalRange.map((afterDays) =>
        formatDaysLater(afterDays, new Date(fulfillmentData.created_at), {
          month: 'short',
          day: 'numeric',
        }),
      )
      : [],
    trackingLink: sortedPackages[0]?.tracking_link ?? '',
    trackingNumber: sortedPackages[0]?.tracking_number ?? '',
  }

  const totalDiscount = itemList.reduce((a, b) => a + b.discount, 0)

  const orderPricingDetails = {
    creditPrice: giftCardAmount,
    tax: fulfillmentData?.total_tax ?? 0,
    grandTotal: finalOrderTotal,
    discount: totalDiscount,
  }

  const ecpInfo = ecpInfoNormalize({
    firstName: sortedPackages[0]?.items?.[0]?.patient?.first_name ?? '',
    lastName: sortedPackages[0]?.items?.[0]?.patient?.last_name ?? '',
    ecpPartnerId: fulfillmentData?.partner_id ?? '',
  })

  const nonEyebotrxItem = sortedPackages.filter(
    (item: { type: string }) => item?.type !== 'virtual',
  )?.[0]

  const eyebotInfo = {
    firstName: fulfillmentData?.customer_details?.first_name,
    lastName: fulfillmentData?.customer_details?.last_name,
    email: fulfillmentData?.customer_details?.email,
    phoneNumber: fulfillmentData?.customer_details?.phone_number,
    placedAt: fulfillmentData?.placed_at ?? '',
    status: nonEyebotrxItem?.status,
    trackingNumber: nonEyebotrxItem?.tracking_number ?? '',
    trackingUrl: nonEyebotrxItem?.tracking_link ?? '',
  }

  return {
    orderId: sortedPackages[0]?.items?.[0]?.order_id ?? '',
    shippingAddress: normalizedShippingAddress,
    billingAddress: normalizedBillingAddress,
    giftType,
    payments: creditCardInfo,
    shippingMethod,
    items: itemList,
    prices: orderPricingDetails,
    ecpInfo,
    eyebotInfo,
    status: sortedPackages[0]?.status,
    customerDetails: fulfillmentData?.customer_details,
    adminPurchase: backendData?.adminPurchase ?? false,
    caRxHandlingFee: fulfillmentData?.ca_rx_handling_fee ?? 0,
  }
}

export {
  formatTelPhoneDefault,
  giftTypeNormalize,
  orderAddressNormalize,
  fulfillmentAddressNormalize,
  prismNormalize,
  prescriptionInformationNormalize,
  ecpInfoNormalize,
  orderDetailsNormalize,
}
