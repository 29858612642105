import { useZenniFetch } from '@/composables/useZenniFetch'

export type StoreSearchParams = {
  partnerName: string
  partnerBrand?: string
  partnerRegion?: string
}
export type StoreSearchResponse = {
  storeId: string
  storeCode: string
  storeName: string
  brand: string
  partnerId: string
  algoliaIndex: string
  catalog: string[]
  catalogFilter: {
    product: string
    'product-variant': string
  }
  frameFilter?: string
}

export const useStore = () => {
  const search = (query: StoreSearchParams) => {
    return useZenniFetch<StoreSearchResponse>('/redemption/api/store/search', {
      method: 'GET',
      query,
    })
  }

  return {
    search,
  }
}
