import { defineNuxtPlugin } from '#imports'
import {
  useGetAlgoliaConfigIndex,
  useZenniAlgoliaSearch,
} from '@/composables/useAlgolia'

type Values = {
  label?: {
    type: string
    data: string
  }
  name?: {
    type: string
    data: string
  }
  imageurl?: {
    type: string
    data: string
  }
  description?: {
    type: string
    data: string
  }
  subdescription?: {
    type: string
    data: string
  }
  order?: {
    type: string
    data: number
  }
  tooltiptext?: {
    type: string
    data: number
  }
  tooltiptitle?: {
    type: string
    data: number
  }
}
export interface ZnConfigEntity {
  objectId: string
  objectType: string
  code: string
  reference_entity_code: string
  values: Values
}

type ConfigKey =
  | 'brandConfig'
  | 'shapeConfig'
  | 'genderConfig'
  | 'colorConfig'
  | 'materialConfig'
  | 'rimConfig'
  | 'lensConfig'
  | 'lensIndexConfig'
  | 'photochromicConfig'
  | 'sunglassConfig'
  | 'tintConfig'
  | 'progressiveTypeConfig'
  | 'featureConfig'

type ConfigReference = {
  algoliaCode: string
  configKey: ConfigKey
}

export default defineNuxtPlugin(async () => {
  // this plugin is used to fetch the algolia config data on SSR
  const configReferences: ConfigReference[] = [
    { algoliaCode: 'brand', configKey: 'brandConfig' as const },
    { algoliaCode: 'frameshapes', configKey: 'shapeConfig' as const },
    { algoliaCode: 'framegenders', configKey: 'genderConfig' as const },
    { algoliaCode: 'color', configKey: 'colorConfig' as const },
    { algoliaCode: 'framematerials', configKey: 'materialConfig' as const },
    { algoliaCode: 'framerimtypes', configKey: 'rimConfig' as const },
    { algoliaCode: 'lenstype', configKey: 'lensConfig' as const },
    { algoliaCode: 'lensindex', configKey: 'lensIndexConfig' as const },
    {
      algoliaCode: 'photochromictype',
      configKey: 'photochromicConfig' as const,
    },
    { algoliaCode: 'sunglassestype', configKey: 'sunglassConfig' as const },
    { algoliaCode: 'tinttypes', configKey: 'tintConfig' as const },
    {
      algoliaCode: 'progressivetype',
      configKey: 'progressiveTypeConfig' as const,
    },
    {
      algoliaCode: 'framefeatures',
      configKey: 'featureConfig' as const,
    },
  ]

  const { search, result } = useZenniAlgoliaSearch<ZnConfigEntity>(
    useGetAlgoliaConfigIndex(),
  )

  await search({
    query: '',
    requestOptions: {
      filters: `objectType:entity-record AND ${configReferences
        .map((ref) => `reference_entity_code:${ref.algoliaCode}`)
        .join(' OR ')}`,
      hitsPerPage: 1000,
    },
  })

  const configs = {} as Record<ConfigKey, ZnConfigEntity[]>
  for (const ref of configReferences) {
    configs[ref.configKey] = []
  }
  // initialize configs with each configKey
  configReferences.forEach((ref) => {
    configs[ref.configKey] =
      result.value?.hits.filter((hit) =>
        hit.reference_entity_code.includes(ref.algoliaCode),
      ) ?? []
  })

  return {
    provide: configs,
  }
})
