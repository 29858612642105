import { useZenniFetch } from '@/composables/useZenniFetch'
import type {
  CampaignGiftGetResponseBody,
  CampaignGiftGuestGetResponseBody,
} from '@/types/redemption'
import type { UseFetchOptions } from '#app'
import { jwtDecode } from 'jwt-decode'

export const useGift = () => {
  const getGiftHandle = (data: any[] | null, isEyebot: boolean) => {
    if (
      !data ||
      data.length === 0 ||
      data.filter(
        ({ isActivated, isUsed }: any) =>
          isActivated && (isEyebot ? !isUsed : true),
      ).length === 0
    ) {
      return null
    }
    return data.filter(
      ({ isActivated, isUsed }: any) =>
        isActivated && (isEyebot ? !isUsed : true),
    )[0]
  }

  const getGift = async (opts: any): Promise<CampaignGiftGetResponseBody> => {
    const { data, error } = await useZenniFetch<CampaignGiftGetResponseBody[]>(
      `/redemption/api/campaign/gift`,
      opts,
    )
    const gift = getGiftHandle(data.value, !!opts?.params?.idempotencyKey)
    if (error.value) {
      throw new Error('can not get available policy id')
    }
    return gift
  }

  const getDependentGifts = (email: string, opts?: UseFetchOptions<any>) => {
    return useZenniFetch<CampaignGiftGetResponseBody[]>(
      `/redemption/api/campaign/gift`,
      {
        method: 'GET',
        query: {
          email,
          includingDependent: true,
          isActivated: true,
        },
        ...opts,
      },
    )
  }

  const getGiftById = (id: string, opts?: UseFetchOptions<any>) => {
    return useZenniFetch<any>(`/redemption/api/campaign/gift/${id}`, {
      ...opts,
      key: `gift-${id}-${Date.now()}`,
    })
  }

  const findGift = (
    email: string,
    opts?: UseFetchOptions<CampaignGiftGuestGetResponseBody>,
  ) => {
    return useZenniFetch<CampaignGiftGuestGetResponseBody>(
      `/redemption/api/campaign/gift/guest`,
      {
        ...opts,
        method: 'GET',
        query: {
          email,
        },
      },
    )
  }

  const getAdminGift = (
    policyId: string,
    storeId: string,
    employerAccessToken?: string,
    opts?: UseFetchOptions<any>,
  ) => {
    return useZenniFetch<CampaignGiftGetResponseBody>(
      `/redemption/api/campaign/policy/${policyId}/admin/gift`,
      {
        method: 'GET',
        ...opts,
        headers: {
          ...opts?.headers,
          'x-zenni-store-id': storeId,
        },
        transform: (data) => {
          if (employerAccessToken) {
            const jwt = jwtDecode<{ email: string; zenniId: string }>(
              employerAccessToken,
            )
            return {
              ...data,
              adminPurchase: true,
              email: jwt.email,
              userId: jwt.zenniId,
            }
          }
          return {
            ...data,
            adminPurchase: true,
          }
        },
      },
    )
  }

  return {
    getGift,
    getGiftById,
    getDependentGifts,
    findGift,
    getAdminGift,
  }
}
